import styled from 'styled-components/macro'

type WrapperProps = {
    width?: number | string
    height?: number | string
}

export const Wrapper = styled.div`
    width: ${({ width }: WrapperProps) => `${width}px`};
    height: ${({ height }: WrapperProps) => `${height}px`};
    display: flex;
    justify-content: center;
    align-items: center;
`

export const Iframe = styled.iframe`
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    border: none;
`
