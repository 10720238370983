import React, {
    useEffect, useRef, useState, useCallback,
} from 'react'
import { Animated, FlatList } from 'react-native-web'
import { AppAtomRenders } from '@apphiveio/controlsmanager/types/RenderComponents'
import valueIsRecord from '@apphiveio/controlsmanager/typeAssertions/valueIsRecord'
import valueIsNumber from '@apphiveio/controlsmanager/typeAssertions/valueIsNumber'
import valueIsArray from '@apphiveio/controlsmanager/typeAssertions/valueIsArray'

const ControlAnimatedFlatList: AppAtomRenders['AnimatedFlatList'] = ({
    initialScrollIndex,
    contentContainerStyle,
    data,
    keyExtractor,
    renderItem,
    horizontal,
    showsHorizontalScrollIndicator,
    bounces,
    decelerationRate,
    style,
    renderToHardwareTextureAndroid,
    snapToInterval,
    snapToAlignment,
    onScrollEndDrag,
    onSwipeToLeft,
    onSwipeToRight,
    onSetLayout,
}) => {
    const flatListRef = useRef<typeof FlatList>(null)
    const divRef = useRef<HTMLDivElement | null>(null)
    const [coordinateX, setCoordinateX] = useState(0)

    const scrollToIndex = useCallback((targetIndex: number) => {
        if (flatListRef.current) {
            flatListRef.current.scrollToIndex({
                index: targetIndex,
                animated: true,
            })
        }
    }, [])
    useEffect(() => {
        if (valueIsNumber(initialScrollIndex)) {
            scrollToIndex(initialScrollIndex)
        }
    }, [initialScrollIndex, scrollToIndex])

    const divStyle = {
        width: style?.width,
        height: style?.height,
    }

    const changePage = useCallback((event: React.MouseEvent) => {
        if (coordinateX > event.pageX && onSwipeToRight) {
            onSwipeToRight()
        }
        if (coordinateX < event.pageX && onSwipeToLeft) {
            onSwipeToLeft()
        }
    }, [coordinateX, onSwipeToLeft, onSwipeToRight])

    const onScrollToIndexFailed = useCallback((event: unknown) => {
        if (!valueIsRecord(event)) {
            return
        }

        const { index } = event

        if (!valueIsNumber(index) || index < 0) {
            return
        }

        if (!valueIsArray(data)) {
            return
        }

        if (index >= data.length) {
            return
        }

        setTimeout(() => {
            scrollToIndex(index)
        }, 100)
    }, [data, scrollToIndex])

    return (
        <div style={divStyle} ref={divRef}>
            <Animated.FlatList
                ref={flatListRef}
                scrollEnabled={false}
                initialScrollIndex={initialScrollIndex}
                contentContainerStyle={contentContainerStyle}
                data={data}
                keyExtractor={keyExtractor}
                renderItem={renderItem}
                horizontal={horizontal}
                showsHorizontalScrollIndicator={showsHorizontalScrollIndicator}
                bounces={bounces}
                decelerationRate={decelerationRate}
                renderToHardwareTextureAndroid={renderToHardwareTextureAndroid}
                snapToInterval={snapToInterval}
                snapToAlignment={snapToAlignment}
                onLayout={() => {
                    const { width, height } = {
                        width: divRef.current?.offsetWidth,
                        height: divRef.current?.offsetHeight,
                    }
                    if (width && height) {
                        onSetLayout({
                            width, height,
                        })
                    }
                }}
                onMouseDown={(event: React.MouseEvent) => {
                    event.preventDefault()
                    setCoordinateX(event.pageX)
                }}
                onMouseUp={changePage}
                onScrollEndDrag={onScrollEndDrag}
                onScrollToIndexFailed={onScrollToIndexFailed}
            />
        </div>
    )
}

export default ControlAnimatedFlatList
