/* eslint-disable react/prop-types */
import React from 'react'
import { AppAtomRenders } from '@apphiveio/controlsmanager/types/RenderComponents'
import Calendar from 'react-calendar'
import { View } from 'react-native-web'

const ControlCalendar: AppAtomRenders['Calendar'] = ({
    current,
    minDate,
    maxDate,
    style,
    onDayPress,
}) => {
    const newMinDate = new Date(minDate || '')
    const newMaxDate = new Date(maxDate || '')
    const valueDate = new Date(current || '')
    const onChangeHandler = (
        value: Date,
    ) => {
        const date = value.toLocaleDateString('en-CA').split('-')
        const dateResponse = {
            year: date[0],
            month: Number(date[1]),
            day: Number(date[2]),
            timestamp: value.getTime(),
            dateString: value.toLocaleDateString('en-CA'),
        }
        if (onDayPress) {
            onDayPress(dateResponse)
        }
    }

    return (
        <View style={style}>
            <Calendar
                value={valueDate}
                minDate={newMinDate}
                maxDate={newMaxDate}
                onChange={onChangeHandler}
            />
        </View>
    )
}

export default ControlCalendar
