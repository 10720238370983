import styled from 'styled-components'

export const MobileFrameDiv = styled.div`
    width: 100vw;
    height: 100vh;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 480px) {
        justify-content: inherit;
    }
`

export const MobileScreen = styled.div<{
    width: number;
    height: number;
}>`
    width: ${({ width }) => `${width}px`};
    height: ${({ height }) => `${height}px`};
    background-color: white;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    box-shadow: 0 2px 4px -1px rgba(0,0,0,.2),0 4px 5px 0 rgba(0,0,0,.14),0 1px 10px 0 rgba(0,0,0,.12);
`
