import React from 'react'
import useAppStateSelector from '@apphiveio/controlsmanager/hooks/useAppStateSelector'
import { Wrapper } from '@googlemaps/react-wrapper'
import appStateSelectBOGoogleApiKey from '@apphiveio/controlsmanager/shared/appStateSelectBOGoogleApiKey'

// eslint-disable-next-line @typescript-eslint/ban-types
const WebappMapsWrapper: React.FC<React.PropsWithChildren<{}>> = ({
    children,
}) => {
    const googleApiKey = useAppStateSelector(appStateSelectBOGoogleApiKey) ?? ''

    return (
        <Wrapper apiKey={googleApiKey} libraries={['places']}>
            {children}
        </Wrapper>
    )
}

export default WebappMapsWrapper
