import type { Callable } from '@apphiveio/controlsmanager/types/Runtime'
import firebaseRealtimeDbGetCallableGetParsed from '@apphiveio/controlsmanager/shared/firebaseRealtimeDbGetCallableGetParsed'
import { odCreatorApp } from 'firebaseConnection'

const firebaseRealtimeDbGetDirectCallable: Callable = async (params) => {
    const args = firebaseRealtimeDbGetCallableGetParsed(params)

    if (!args) {
        return
    }

    const {
        onCatch,
        onThen,
        path,
    } = args

    const database = odCreatorApp.database()

    const reference = database.ref(path)

    reference.once('value')
        .then((snapshot) => {
            onThen(snapshot.val())
        })
        .catch(onCatch)
}

export default firebaseRealtimeDbGetDirectCallable
