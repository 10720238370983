/* eslint-disable prefer-arrow-callback */
import React, {
    useEffect,
    useImperativeHandle,
} from 'react'
import { AppAtomRenders } from '@apphiveio/controlsmanager/types/RenderComponents'
import { valueIsNumber } from 'ts-validators'
import useScannerQRBarcodeFromCameraEffect from 'hooks/useScannerQRBarcodeFromCameraEffect'
import styled from 'styled-components'

const CameraFrame = styled('div')<{
    propWidth: number | string;
    propHeight: number | string;
}>`
    width: ${({ propWidth }) => (valueIsNumber(propWidth) ? `${propWidth}px` : propWidth)};
    height: ${({ propHeight }) => (valueIsNumber(propHeight) ? `${propHeight}px` : propHeight)};
    min-width: ${({ propWidth }) => (valueIsNumber(propWidth) ? `${propWidth}px` : propWidth)};
    min-height: ${({ propHeight }) => (valueIsNumber(propHeight) ? `${propHeight}px` : propHeight)};
    background-color: black;
`

const VideoWithStyle = styled('video')<{
    zoom: number;
}>`
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    transform: ${({ zoom }) => `scale(${zoom + 1})`};
`

const getMediaProviderFromVideoRef = (videoRef: React.RefObject<HTMLVideoElement>) => {
    const stream = videoRef.current?.srcObject
    if (!stream || !(stream instanceof MediaStream)) {
        return undefined
    }
    return stream
}

const ControlCameraView: AppAtomRenders['CameraView'] = React.forwardRef(({
    style,
    device: targetDevice,
    zoom,
    onCodeReaded,
}, cmCameraRef) => {
    const videoRef = React.useRef<HTMLVideoElement>(null)
    const mediaRecorderRef = React.useRef<MediaRecorder | null>(null)

    useImperativeHandle(cmCameraRef, () => ({
        decrementZoom: () => {
            throw new Error('Not implemented')
        },
        incrementZoom: () => {
            throw new Error('Not implemented')
        },
        takePhoto: async () => {
            const stream = getMediaProviderFromVideoRef(videoRef)
            if (!stream) {
                throw new Error('No stream found on camera')
            }

            const firstTrack = stream.getVideoTracks().at(0)

            if (!firstTrack) {
                throw new Error('No video track found on camera')
            }

            if ('ImageCapture' in window) {
                const imageCapture = new ImageCapture(firstTrack)
                const blob = await imageCapture.takePhoto()
                const file = new File([blob], 'image.png', { type: 'image/png' })
                const imageUrl = URL.createObjectURL(file)

                return imageUrl
            }

            const video = videoRef.current
            if (!video) {
                throw new Error('No video element found')
            }

            const canvas = document.createElement('canvas')
            canvas.width = video.videoWidth
            canvas.height = video.videoHeight
            const context = canvas.getContext('2d')
            if (!context) {
                throw new Error('No 2d context found')
            }
            context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight)
            const imageUrl = canvas.toDataURL('image/png')
            return imageUrl
        },
        startRecordingVideo: () => new Promise((resolve, reject) => {
            const stream = getMediaProviderFromVideoRef(videoRef)
            if (!stream) {
                reject(new Error('No stream found on camera'))
                return
            }

            const mimeType = (() => {
                if (MediaRecorder.isTypeSupported('video/webm; codecs=vp9')) {
                    return 'video/webm; codecs=vp9'
                }
                if (MediaRecorder.isTypeSupported('video/webm')) {
                    return 'video/webm'
                }
                if (MediaRecorder.isTypeSupported('video/mp4')) {
                    return 'video/mp4'
                }
                return undefined
            })()

            if (mediaRecorderRef.current) {
                mediaRecorderRef.current.stop()
            }

            const mediaRecorder = new MediaRecorder(stream, { mimeType })

            const blobs: Blob[] = []

            mediaRecorder.addEventListener('dataavailable', (event) => {
                blobs.push(event.data)
            })

            mediaRecorder.addEventListener('stop', () => {
                const completeBlob = new Blob(blobs, { type: mimeType })
                const uri = URL.createObjectURL(completeBlob)
                resolve(uri)
            })

            mediaRecorderRef.current = mediaRecorder
            mediaRecorder.start()
        }),
        stopRecordingVideo: () => {
            if (mediaRecorderRef.current) {
                mediaRecorderRef.current.stop()
            }
        },
    }))

    useEffect(function createVideoStream() {
        const videoElement = videoRef.current
        if (!videoElement) {
            return undefined
        }

        let isCanceled = false
        let stream: MediaStream | undefined

        const getStreamWithDevice = async () => {
            const devices = await navigator.mediaDevices.enumerateDevices()

            const matchedDevice = devices.find((device) => {
                if (targetDevice === 'front') {
                    return device.label.toLowerCase().includes('front')
                }
                return device.label.toLowerCase().includes('back')
            })

            return navigator.mediaDevices.getUserMedia({
                video: matchedDevice ? { deviceId: matchedDevice.deviceId } : true,
                audio: true,
            })
        }

        getStreamWithDevice().then((newStream) => {
            if (isCanceled) {
                return
            }
            stream = newStream
            videoElement.srcObject = newStream
        })

        return () => {
            isCanceled = true
            if (videoElement.srcObject === stream) {
                videoElement.srcObject = null
            }
            if (stream) {
                stream.getTracks().forEach((track) => track.stop())
                stream = undefined
            }
        }
    }, [targetDevice])

    useScannerQRBarcodeFromCameraEffect(videoRef, onCodeReaded)

    return (
        <CameraFrame
            propWidth={style.width}
            propHeight={style.height}
        >
            <VideoWithStyle
                ref={videoRef}
                autoPlay
                playsInline
                zoom={zoom}
            />
        </CameraFrame>
    )
})

export default ControlCameraView
