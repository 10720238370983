import React, { useMemo } from 'react'
import { copyPropsIfDefined } from '@apphiveio/controlsmanager/shared'
import { ImageProps } from '@apphiveio/controlsmanager/types/RenderComponents'

const ControImage: React.FC<ImageProps> = ({
    source,
    style,
    resizeMode = 'cover',
}) => {
    const imageSizeMode = useMemo(() => {
        switch (resizeMode) {
        case 'cover':
            return {
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            }
        case 'contain':
            return {
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
            }
        case 'stretch':
            return {
                backgroundSize: '100% 100%',
            }
        case 'repeat':
            return {
                backgroundSize: '85%',
                backgroundRepeat: 'repeat',
            }
        case 'center':
            return {
                backgroundSize: '100%',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
            }
        default:
            return {
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            }
        }
    }, [resizeMode])

    const webSource = useMemo(() => {
        if (typeof source !== 'number' && !Array.isArray(source)) {
            return source.uri
        }
        return undefined
    }, [source])

    const imageStyle = useMemo(() => {
        const computedStyle: Record<string, unknown> = { ...imageSizeMode }

        if (style) {
            computedStyle.width = style.width
            computedStyle.height = style.height

            copyPropsIfDefined(
                style,
                computedStyle,
                ['borderTopLeftRadius', 'borderTopRightRadius', 'borderBottomLeftRadius', 'borderBottomRightRadius',
                    'borderWidth', 'borderLeftWidth', 'borderRightWidth', 'borderTopWidth', 'borderBottomWidth',
                ],
            )

            if (style.borderWidth || style.borderLeftWidth
                || style.borderRightWidth || style.borderTopWidth
                || style.borderBottomWidth
            ) {
                computedStyle.borderStyle = 'solid'
                computedStyle.borderColor = style.borderColor?.toString()
            }
        }

        if (webSource) {
            computedStyle.backgroundImage = `url(${webSource})`
        }

        return computedStyle
    }, [style, imageSizeMode, webSource])

    return (
        <div style={imageStyle} />
    )
}

export default ControImage
