const generateImageCanvasFromCamera = (cameraElement: HTMLVideoElement) => {
    let width = cameraElement.videoWidth
    if (width <= 0) {
        width = cameraElement.offsetWidth
    }
    let height = cameraElement.videoHeight
    if (height <= 0) {
        height = cameraElement.offsetHeight
    }
    const canvas = document.createElement('canvas')
    canvas.width = width
    canvas.height = height
    const context = canvas.getContext('2d')
    if (!context) {
        return undefined
    }
    context.drawImage(cameraElement, 0, 0, width, height)
    return {
        canvas,
        context,
        dimensions: { width, height },
    }
}

export default generateImageCanvasFromCamera
