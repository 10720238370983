import React, { useMemo } from 'react'
import { copyPropsIfDefined } from '@apphiveio/controlsmanager/shared'
import { ImageBackgroundProps } from '@apphiveio/controlsmanager/types/RenderComponents'

const ControlImageBackground: React.FC<ImageBackgroundProps> = ({
    source,
    children,
    style,
    resizeMode,
}) => {
    const imageSizeMode = useMemo(() => {
        switch (resizeMode || 'cover') {
        case 'cover':
            return {
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            }
        case 'contain':
            return {
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
            }
        case 'stretch':
            return {
                backgroundSize: '100% 100%',
            }
        case 'repeat':
            return {
                backgroundSize: '100%',
                backgroundRepeat: 'repeat',
            }
        case 'center':
            return {
                backgroundSize: '60%',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
            }
        default:
            return {
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            }
        }
    }, [resizeMode])

    const styleComposed: React.CSSProperties = useMemo(() => {
        const divStyles: Record<string, unknown> = {
            display: 'flex',
            maxHeight: '100%',
            ...imageSizeMode,
        }

        if (style) {
            copyPropsIfDefined(
                style,
                divStyles,
                [
                    'alignContent', 'alignItems', 'alignSelf', 'borderBottomWidth',
                    'borderLeftWidth', 'borderRightWidth', 'borderTopWidth', 'borderWidth',
                    'borderStyle',
                    'bottom',
                    'display',
                    'flex',
                    'flexBasis',
                    'flexDirection',
                    'flexGrow,',
                    'flexShrink',
                    'flexWrap',
                    'height',
                    'justifyContent',
                    'left',
                    'margin',
                    'marginBottom',
                    'marginLeft',
                    'marginRight',
                    'marginTop',
                    'maxHeight',
                    'maxWidth',
                    'minHeight',
                    'minWidth',
                    'overflow',
                    'padding',
                    'paddingBottom',
                    'paddingLeft',
                    'paddingRight',
                    'paddingTop',
                    'position',
                    'right',
                    'top',
                    'width',
                    'zIndex',
                    'backfaceVisibility',
                    'backgroundColor',
                    'borderColor',
                    'opacity',
                ],
            )

            copyPropsIfDefined(
                style,
                divStyles,
                [
                    'borderRadius', 'borderBottomLeftRadius',
                    'borderBottomRightRadius', 'borderTopLeftRadius',
                    'borderTopRightRadius',
                ],
                (value) => {
                    const stringValue = `${value}`
                    if (stringValue.includes('px') || stringValue.includes('%')) {
                        return value
                    }
                    return `${value}px`
                },
            )
        }

        if (typeof source !== 'number' && !Array.isArray(source)) {
            divStyles.backgroundImage = `url(${source.uri})`
        }

        return divStyles
    }, [imageSizeMode, style, source])

    return (
        <div style={styleComposed} apphive-component="ImageBackground">
            {children}
        </div>
    )
}

export default ControlImageBackground
