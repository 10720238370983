import { Callable } from '@apphiveio/controlsmanager/types/Runtime'

const buildCallableNotSupported = (functionName: string): Callable => ({
    processor,
}) => {
    processor.root.alert(
        'Not supported',
        `This function [${functionName}] is not supported in the web app`,
    )
}

export default buildCallableNotSupported
