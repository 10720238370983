import React from 'react'
import useMobileScreenSize from '../../../hooks/useMobileScreenSize'
import {
    MobileFrameDiv,
    MobileScreen,
} from './style'

const MobileFrame: React.FC = ({
    children,
}) => {
    const dimentions = useMobileScreenSize()

    return (
        <MobileFrameDiv>
            <MobileScreen
                width={dimentions.width}
                height={dimentions.height}
            >
                {children}
            </MobileScreen>
        </MobileFrameDiv>
    )
}

export default MobileFrame
