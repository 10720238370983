import React from 'react'
import Video from 'react-player'
import { ImageBackground } from 'react-native-web'
import { AppAtomRenders } from '@apphiveio/controlsmanager/types/RenderComponents'
import { Wrapper } from './style'

const ControlVideoView: AppAtomRenders['VideoView'] = ({
    style,
    videoConfig,
}) => (
    videoConfig?.url ? (
        <Wrapper style={style as React.CSSProperties}>
            <Video url={videoConfig.url} controls width="100%" height="100%" />
        </Wrapper>
    ) : (
        <ImageBackground
            source={{ uri: 'https://womenandbloodclots.org/wp-content/uploads/2015/05/video-placeholder.png' }}
            style={style}
        />
    )
)

export default ControlVideoView
