import { CameraCodeEvent } from '@apphiveio/controlsmanager/types/RenderComponents'
import { useEffect } from 'react'
import readQRBarcodeFromCamera from '../shared/readQRBarcodeFromCamera'

const useScannerQRBarcodeFromCameraEffect = (
    cameraRef: React.RefObject<HTMLVideoElement>,
    onQRBarcodeRead?: (data: CameraCodeEvent) => void,
) => useEffect(() => {
    const camera = cameraRef.current
    if (!camera || !onQRBarcodeRead) {
        return () => undefined
    }
    camera.muted = true
    const scannerQRBarcode = () => {
        const code = readQRBarcodeFromCamera(camera)
        if (code) {
            onQRBarcodeRead(code)
        }
    }
    const cron = setInterval(scannerQRBarcode, 1000)
    return () => {
        clearInterval(cron)
    }
}, [cameraRef, onQRBarcodeRead])

export default useScannerQRBarcodeFromCameraEffect
