import { Callable } from '@apphiveio/controlsmanager/types/Runtime'

const openCalendar: Callable = ({
    executeProcesses,
    callbacks,
}) => {
    try {
        if (navigator.userAgent.includes('Mac')) {
            window.open('ical://', '_BLANK')?.focus()
        } else {
            window.open('webcal://', '_BLANK')?.focus()
        }

        executeProcesses(
            undefined,
            callbacks.successProcess,
        )
    } catch {
        executeProcesses(
            undefined,
            callbacks.errorProcess,
        )
    }
}

export default openCalendar
