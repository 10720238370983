import type { Callable } from '@apphiveio/controlsmanager/types/Runtime'
import firebaseRealtimeDbDeleteCallableGetParsed from '@apphiveio/controlsmanager/shared/firebaseRealtimeDbDeleteCallableGetParsed'
import { odCreatorApp } from 'firebaseConnection'

const firebaseRealtimeDbDeleteDirectCallable: Callable = async (params) => {
    const args = firebaseRealtimeDbDeleteCallableGetParsed(params)

    if (!args) {
        return
    }

    const {
        onCatch,
        onThen,
        path,
    } = args

    odCreatorApp
        .database()
        .ref(path)
        .remove()
        .then(onThen)
        .catch(onCatch)
}

export default firebaseRealtimeDbDeleteDirectCallable
