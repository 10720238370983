/* eslint-disable indent */
/* eslint-disable comma-dangle */
/* eslint-disable no-trailing-spaces */

type StaticImages = {
    readonly [fileName: string]: {
        uri?: string;
    };
}

const imageStaticSourcesByFileName: StaticImages = {
    'cJyjjXiYZ1HgwLwsHjR4dL.jpg': { uri: '/images/cJyjjXiYZ1HgwLwsHjR4dL.jpg' },
'vpZNqxLCeJeEoUvWUm2ray.png': { uri: '/images/vpZNqxLCeJeEoUvWUm2ray.png' },
'5XP4qtCAaVoS86kLK1NiVH.png': { uri: '/images/5XP4qtCAaVoS86kLK1NiVH.png' },
'fiej7NacwAy76SJVMsMYwL.png': { uri: '/images/fiej7NacwAy76SJVMsMYwL.png' },
'pcmT9JqCxC8ocHNZymt5ww.png': { uri: '/images/pcmT9JqCxC8ocHNZymt5ww.png' },
'fQHUykdSvStp798nanep4h.png': { uri: '/images/fQHUykdSvStp798nanep4h.png' },
'x66advXn9MTLtkgNJkLFRN.png': { uri: '/images/x66advXn9MTLtkgNJkLFRN.png' },
'4GpsmcwLRdmmDGuYx9GZVk.png': { uri: '/images/4GpsmcwLRdmmDGuYx9GZVk.png' },
'fvvrao89oyN2Ndnegk8XTD.png': { uri: '/images/fvvrao89oyN2Ndnegk8XTD.png' },
'eXYmzn4Ssu8JGxhNM2QKzY.png': { uri: '/images/eXYmzn4Ssu8JGxhNM2QKzY.png' },
'dVjLdezwVcFz2iPHa3DeHd.png': { uri: '/images/dVjLdezwVcFz2iPHa3DeHd.png' },
'3YrvuYK38L3cYjqqt73TUn.png': { uri: '/images/3YrvuYK38L3cYjqqt73TUn.png' },
'gnYi9ycdbPV8CCZ8oeZi7Y.png': { uri: '/images/gnYi9ycdbPV8CCZ8oeZi7Y.png' },
'9e4NDNCo2UajVE1y1cux8c.png': { uri: '/images/9e4NDNCo2UajVE1y1cux8c.png' },
'kxwSvhBqyj3PC2md6ot4X6.png': { uri: '/images/kxwSvhBqyj3PC2md6ot4X6.png' },
'5TSCqc9W4ne59kZ83rACoE.png': { uri: '/images/5TSCqc9W4ne59kZ83rACoE.png' },
'cbot8DWbsQCUwtmqpxVjUE.jpg': { uri: '/images/cbot8DWbsQCUwtmqpxVjUE.jpg' },
'4p1pMY6Dnvu1CHU7y2sj1a.png': { uri: '/images/4p1pMY6Dnvu1CHU7y2sj1a.png' },
'pbVdGT2XAKrxKD1onhcLkQ.png': { uri: '/images/pbVdGT2XAKrxKD1onhcLkQ.png' },
'wKhg31y8Bi4j4P4Y15rNwj.jpg': { uri: '/images/wKhg31y8Bi4j4P4Y15rNwj.jpg' },
'1KqXMBvCNrZcDE47pzTVJa.jpg': { uri: '/images/1KqXMBvCNrZcDE47pzTVJa.jpg' },
'n8DtrJPNBd27CLQQ2opVqB.jpg': { uri: '/images/n8DtrJPNBd27CLQQ2opVqB.jpg' },
'nrGz8agT8CdQN5NqCJvbXW.jpg': { uri: '/images/nrGz8agT8CdQN5NqCJvbXW.jpg' },
'7sziQHQWSfq29GTJ4YY8Td.png': { uri: '/images/7sziQHQWSfq29GTJ4YY8Td.png' },
'3vuF5yXeJEhbGxKJAM53m2.png': { uri: '/images/3vuF5yXeJEhbGxKJAM53m2.png' },
'8w2k7pFDajuiC1VcQovt3r.jpg': { uri: '/images/8w2k7pFDajuiC1VcQovt3r.jpg' },
'fW9XGD36BS66yYL1Bqvy8i.jpg': { uri: '/images/fW9XGD36BS66yYL1Bqvy8i.jpg' },
'quGeP9oC5BrrMngwF1rePV.jpg': { uri: '/images/quGeP9oC5BrrMngwF1rePV.jpg' }
}

export default imageStaticSourcesByFileName
