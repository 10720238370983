import React, {
    useCallback,
    useEffect,
    useRef,
    useState,
    useMemo,
} from 'react'
import Tooltip from '../../atoms/Tooltip'
import useWindowResizeEffect from '../../../hooks/useWindowResizeEffect'
import useWindowScrollEffect from '../../../hooks/useWindowScrollEffect'

export enum ATTACHEDTOOLTIP_POINTS {
    TOP_LEFT,
    TOP_MIDDLE,
    TOP_RIGHT,
    BOTTOM_LEFT,
    BOTTOM_MIDDLE,
    BOTTOM_RIGHT,
    MIDDLE_RIGH,
    MIDDLE_LEFT,
}

export enum TOOLTIP_OVERFLOW_DIRECTION {
    TOP,
    BOTTOM,
    LEFT,
    RIGHT,
}

const getCoordinateByPoint = (point: ATTACHEDTOOLTIP_POINTS, rec: {
    width: number;
    height: number;
    x: number;
    y: number;
}) => {
    let x: number
    let y: number

    switch (point) {
    case ATTACHEDTOOLTIP_POINTS.TOP_RIGHT:
        x = rec.x + rec.width
        y = rec.y
        break
    case ATTACHEDTOOLTIP_POINTS.TOP_MIDDLE:
        x = rec.x + (rec.width / 2)
        y = rec.y
        break
    case ATTACHEDTOOLTIP_POINTS.BOTTOM_LEFT:
        x = rec.x
        y = rec.y + rec.height
        break
    case ATTACHEDTOOLTIP_POINTS.BOTTOM_MIDDLE:
        x = rec.x + (rec.width / 2)
        y = rec.y + rec.height
        break
    case ATTACHEDTOOLTIP_POINTS.BOTTOM_RIGHT:
        x = rec.x + rec.width
        y = rec.y + rec.height
        break
    case ATTACHEDTOOLTIP_POINTS.MIDDLE_LEFT:
        x = rec.x
        y = rec.y + (rec.height / 2)
        break
    case ATTACHEDTOOLTIP_POINTS.MIDDLE_RIGH:
        x = rec.x + rec.width
        y = rec.y + (rec.height / 2)
        break
    case ATTACHEDTOOLTIP_POINTS.TOP_LEFT:
    default:
        x = rec.x
        y = rec.y
    }

    return { x, y }
}

type Props = {
    width: 'inherited' | number;
    height: number;
    parentPoint: ATTACHEDTOOLTIP_POINTS;
    anchorPoint: ATTACHEDTOOLTIP_POINTS;
    renderParent: (
        parentRef: React.MutableRefObject<HTMLDivElement | null>,
    ) => React.ReactElement;
    onScreenOverflow?: (direction: TOOLTIP_OVERFLOW_DIRECTION) => void;
}

const AttachedTooltip: React.FC<Props> = ({
    width,
    height,
    parentPoint,
    anchorPoint,
    renderParent,
    children,
    onScreenOverflow,
}) => {
    const parentRef = useRef<HTMLDivElement | null>(null)
    const [parentRect, setParentRect] = useState<undefined | DOMRect>(undefined)

    const recalculatePosition = useCallback(() => {
        if (!parentRef.current) {
            return
        }
        setParentRect(parentRef.current.getBoundingClientRect())
    }, [])

    useEffect(() => {
        const parent = parentRef.current

        if (!parent) {
            return undefined
        }

        const resizeObserver = new ResizeObserver(recalculatePosition)

        resizeObserver.observe(parent)

        return () => {
            resizeObserver.disconnect()
        }
    }, [recalculatePosition])

    useWindowResizeEffect(recalculatePosition)
    useWindowScrollEffect(recalculatePosition)

    const points = useMemo(() => {
        if (!parentRect) {
            return undefined
        }

        const actualWidth = width === 'inherited' ? parentRect.width : width

        const target = getCoordinateByPoint(parentPoint, parentRect)
        const anchor = getCoordinateByPoint(anchorPoint, {
            width: actualWidth,
            height,
            x: 0,
            y: 0,
        })

        return {
            target,
            anchor,
            actualWidth,
            height,
        }
    }, [parentRect, anchorPoint, height, parentPoint, width])

    useEffect(() => {
        if (!points || !onScreenOverflow) {
            return
        }

        const xRightLimit = points.target.x + points.actualWidth - points.anchor.x
        const xLeftLimit = points.target.x - points.anchor.x

        const yBottomLimit = points.target.y + points.height - points.anchor.y
        const yTopLimit = points.target.y - points.anchor.y

        if (xRightLimit > document.body.clientWidth) {
            onScreenOverflow(TOOLTIP_OVERFLOW_DIRECTION.RIGHT)
        }
        if (xLeftLimit < 0) {
            onScreenOverflow(TOOLTIP_OVERFLOW_DIRECTION.LEFT)
        }
        if (yBottomLimit > document.body.clientHeight) {
            onScreenOverflow(TOOLTIP_OVERFLOW_DIRECTION.BOTTOM)
        }
        if (yTopLimit < 0) {
            onScreenOverflow(TOOLTIP_OVERFLOW_DIRECTION.TOP)
        }
    }, [points, onScreenOverflow])

    if (!points) {
        return renderParent(parentRef)
    }

    return (
        <>
            {renderParent(parentRef)}
            <Tooltip
                anchorX={points.anchor.x}
                anchorY={points.anchor.y}
                targetX={points.target.x}
                targetY={points.target.y}
                height={height}
                width={points.actualWidth}
            >
                {children}
            </Tooltip>
        </>
    )
}

export default AttachedTooltip
