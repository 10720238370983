import styled from 'styled-components/macro'

export const Frame = styled.div`
    width: 100%;
    height: 100%;
    background-color: #1a2b3459;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
`

export const Container = styled.div`
    width: 300px;
    max-height: 600px;
    flex-direction: column;
    background-color: white;
    overflow: hidden;
    border-radius: 3px;
    padding: 5px;
    overflow-y: auto;
    &&::-webkit-scrollbar {
        width: 5px; 
    } 
    &&::-webkit-scrollbar-track {
        background: transparent;
    }
    &&::-webkit-scrollbar-thumb {
        background-color: #bbb;
        border-radius: 3px;
        border: 0px solid transparent;
        width: 5px;
    }
`

export const Picker = styled.div`
    align-items: center;
    cursor: pointer;
    display: flex;
`

export const Modal = styled.div`
    overflow-y: auto;
    overflow-x: hidden;
    background-color: #fff;
    border-radius: 4px;
`

export const ModalItem = styled.div`
    padding: 12px 5px 12px 5px;
    cursor: pointer;
`
