import React, { useRef, useImperativeHandle } from 'react'
import { TextInput } from 'react-native-web'
import { AppAtomRenders } from '@apphiveio/controlsmanager/types/RenderComponents'

const ControlTextInput: AppAtomRenders['TextInput'] = React.forwardRef(({
    editable,
    style,
    keyboardType,
    placeholder,
    multiline,
    maxLength,
    placeholderTextColor,
    value,
    secureTextEntry,
    returnKeyType,
    blurOnSubmit,
    onSubmitEditing,
    onFocus,
    onChangeText,
    onBlur,
}, textInputRef) => {
    const inputRef = useRef<typeof TextInput | null>(null)

    useImperativeHandle(textInputRef, () => ({
        focus: () => {
            if (inputRef.current) {
                inputRef.current.focus()
            }
        },
    }), [])

    return (
        <TextInput
            ref={inputRef}
            style={style}
            keyboardType={keyboardType}
            placeholder={placeholder}
            multiline={multiline}
            maxLength={maxLength}
            placeholderTextColor={placeholderTextColor}
            value={value}
            secureTextEntry={secureTextEntry}
            returnKeyType={returnKeyType}
            blurOnSubmit={blurOnSubmit}
            editable={editable}
            onSubmitEditing={onSubmitEditing}
            onFocus={onFocus}
            onChangeText={onChangeText}
            onBlur={onBlur}
        />
    )
})

export default ControlTextInput
