import React, { useMemo, useState, useCallback } from 'react'
import { odCreatorApp } from 'firebaseConnection'
import {
    View,
    Text,
    TouchableOpacity,
    ImageBackground,
    TextInput,
    ScrollView,
    Animated,
    Modal,
    FlatList,
} from 'react-native-web'
import store from 'store'
import firebase from 'firebase/compat/app'
import PreviewerContext, { PreviewerContextType, PreviewerTheme } from '@apphiveio/controlsmanager/PreviewerContext'
import { buildPreviewerUpdateRouteStackAction } from '@apphiveio/controlsmanager/previewerState/previewerStateActionBuilders'
import ControlLottie from 'components/molecules/ControlLottie'
import ControlAnimatedFlatList from 'components/molecules/ControlAnimatedFlatList'
import { ApplicationRouteStack } from '@apphiveio/controlsmanager/types/AppRouting'
import buildRoutingHandler from '@apphiveio/controlsmanager/applicationFunctionality/buildRoutingHandler'
import useWindowResizeEffect from 'hooks/useWindowResizeEffect'
import buildPreviewerNavigation from '@apphiveio/controlsmanager/shared/buildPreviewerNavigation'
import buildPreviewerAuthentication from '@apphiveio/controlsmanager/shared/buildPreviewerAuthentication'
import getI18nLocale from 'shared/getI18Locale'
import authLoginWithProvider from 'shared/authLoginWithProvider'
import windowAlert from 'shared/windowAlert'
import { setRendererCurrentUser } from 'store/actions/application'
import { authUserCreate, setAuthUserLocale } from './graphql'
import apphiveLogo from '../../../assets/images/apphiveLogo.png'
import blockApp from '../../../assets/images/block.png'
import backgroundlist from '../../../assets/images/background.png'
import emoji from '../../../assets/images/emoji.png'
import robot from '../../../assets/images/robot.png'

type ScreenDimentions = {
    width: number;
    height: number;
}

const initialDimentions = {
    width: 339,
    height: 639,
}

const PreviewerAppContext: React.FC = ({ children }) => {
    const [dimentions, setDimentions] = useState<ScreenDimentions>(initialDimentions)

    const calculateSize = useCallback(() => {
        const windowWidth = window.innerWidth
        const windowHeight = window.innerHeight

        if (windowWidth > windowHeight) {
            setDimentions(initialDimentions)
        } else {
            setDimentions({
                width: windowWidth,
                height: windowHeight,
            })
        }
    }, [])

    useWindowResizeEffect(calculateSize)

    const previewerAppContext: PreviewerContextType = useMemo(() => {
        const routing = buildRoutingHandler({
            maxRoutesOnStack: 40,
            getRoutingStack: () => store.getState().previewer.routeStack,
            onSetRoutingStack: (newState: ApplicationRouteStack) => {
                store.dispatch(buildPreviewerUpdateRouteStackAction(newState))
            },
        })
        const navigation = buildPreviewerNavigation(routing)

        return {
            mapfontFamilyToFontName: (fontFamily) => {
                switch (fontFamily) {
                case 'material':
                    return 'MaterialIconsRegular'
                case 'AntDesign':
                    return 'AntDesign'
                case 'Foundation':
                    return 'Foundation'
                case 'MaterialCommunityIcons':
                    return 'MaterialCommunityIcons'
                case 'SimpleLineIcons':
                    return 'SimpleLineIcons'
                case 'Zocial':
                    return 'Zocial'
                default:
                    return fontFamily
                }
            },
            theme: PreviewerTheme,
            lottieSources: {
                apphiveLoading: require('../../../assets/lotties/apphiveLoading.json'),
            },
            Render: {
                SafeAreaView: View,
                Text,
                TouchableOpacity,
                View,
                Image: ImageBackground,
                ImageBackground,
                TextInput,
                ScrollView,
                KeyboardAvoidingView: View,
                AnimatedFlatList: ControlAnimatedFlatList,
                Lottie: ControlLottie,
                StatusBar: () => null,
                AnimatedView: Animated.View,
                FlatList,
                Modal,
                InterstitialAd: () => null,
            },
            imageSources: {
                apphiveLogo,
                blockApp,
                backgroundlist,
                emoji,
                robot,
            },
            routing,
            navigation,
            dimentions: {
                window: dimentions,
                screen: dimentions,
                statusBarHeight: 0,
            },
            Animated,
            firebase: {
                auth: () => odCreatorApp.auth(),
            },
            auth: buildPreviewerAuthentication({
                getLocalStorage: (key) => localStorage.getItem(key),
                setLocalStorage: (key, value) => localStorage.setItem(key, value),
                removeLocalStorage: (key) => localStorage.removeItem(key),
                getFirebaseFunctions: () => odCreatorApp.functions(),
                getFirebaseAuth: () => odCreatorApp.auth(),
                setAuthUserLocale: async () => {
                    setAuthUserLocale()({ locale: getI18nLocale() })
                },
                setAuthUser: async (data) => authUserCreate()(data),
                setRendererCurrentUser,
                signInToFirebaseWithGoogle: async () => {
                    authLoginWithProvider({
                        firebaseApp: odCreatorApp,
                        provider: new firebase.auth.GoogleAuthProvider(),
                        mode: 'popup',
                    })
                },
                signInToFirebaseWithFacebook: async () => {
                    authLoginWithProvider({
                        firebaseApp: odCreatorApp,
                        provider: new firebase.auth.FacebookAuthProvider(),
                        mode: 'popup',
                    })
                },
                getI18nLocale,
                googleSignOut: async () => undefined,
            }),
            alert: windowAlert,
        }
    }, [dimentions])

    return (
        <PreviewerContext.Provider
            value={previewerAppContext}
        >
            {children}
        </PreviewerContext.Provider>
    )
}

export default PreviewerAppContext
