import { BrowserMultiFormatReader, BarcodeFormat } from '@zxing/browser'
import { CODE_TYPES } from '@apphiveio/controlsmanager/types/RenderComponents'
import generateImageCanvasFromCamera from './generateImageCanvasFromCamera'

const readQRBarcodeFromCamera = (cameraElement: HTMLVideoElement) => {
    try {
        const generateImageCanvas = generateImageCanvasFromCamera(cameraElement)
        if (!generateImageCanvas) {
            return undefined
        }
        const { canvas, dimensions: { width, height } } = generateImageCanvas
        const reader = new BrowserMultiFormatReader()
        const code = reader.decodeFromCanvas(canvas)
        const data = code.getText()
        const type = code.getBarcodeFormat()
        const origin = code.getResultPoints().map((point) => ({ x: point.getX(), y: point.getY() }))
        if (type === BarcodeFormat.QR_CODE) {
            return {
                data,
                type: CODE_TYPES.QR,
                bounds: {
                    width,
                    height,
                    origin,
                },
            }
        }

        return {
            data,
            type: CODE_TYPES.BAR,
            bounds: {
                width,
                height,
                origin,
            },
        }
    } catch {
        return undefined
    }
}

export default readQRBarcodeFromCamera
