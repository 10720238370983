import { useCallback, useState } from 'react'
import useWindowResizeEffect from './useWindowResizeEffect'

type ScreenDimentions = {
    width: number;
    height: number;
}

const defaultDimentions: ScreenDimentions = {
    width: 339,
    height: 639,
}

const useMobileScreenSize = () => {
    const [dimentions, setDimentions] = useState<ScreenDimentions>(defaultDimentions)

    const calculateSize = useCallback(() => {
        const windowWidth = window.innerWidth
        const windowHeight = window.innerHeight

        if (windowWidth > windowHeight) {
            setDimentions(defaultDimentions)
        } else {
            setDimentions({
                width: windowWidth,
                height: windowHeight,
            })
        }
    }, [])

    useWindowResizeEffect(calculateSize)

    return dimentions
}

export default useMobileScreenSize
