import React, { useCallback, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import useWindowDimentions from '../../../hooks/useWindowDimentions'

let tooltipPortalMainDiv: HTMLDivElement | undefined

const initializeTooltipPortalDiv = () => {
    if (tooltipPortalMainDiv === undefined) {
        tooltipPortalMainDiv = document.createElement('div')
        tooltipPortalMainDiv.style.zIndex = '999999px'
        tooltipPortalMainDiv.style.position = 'relative'
        tooltipPortalMainDiv.style.width = '0px'
        tooltipPortalMainDiv.style.height = '0px'
        document.body.appendChild(tooltipPortalMainDiv)
    }
    return tooltipPortalMainDiv
}

type Props = {
    width: number;
    height: number;
    targetX: number;
    targetY: number;
    anchorX: number;
    anchorY: number;
}

const Tooltip: React.FC<Props> = ({
    height,
    width,
    targetX,
    targetY,
    anchorX,
    anchorY,
    children,
}) => {
    const windowsDimentions = useWindowDimentions()
    const [divRef, setDivRef] = useState<undefined | HTMLDivElement>(undefined)

    const convertToGlobalCoordinates = useCallback((left: number, top: number) => ({
        left,
        top: top - windowsDimentions.height,
    }), [windowsDimentions.height])

    useEffect(() => {
        const mainTooltipDiv = initializeTooltipPortalDiv()

        const createdDiv = document.createElement('div')
        mainTooltipDiv.appendChild(createdDiv)
        setDivRef(createdDiv)

        return () => {
            mainTooltipDiv.removeChild(createdDiv)
        }
    }, [])

    useEffect(() => {
        if (!divRef) {
            return
        }
        divRef.style.width = `${width}px`
        divRef.style.height = `${height}px`
        divRef.style.position = 'absolute'

        const initialCoordinates = convertToGlobalCoordinates(targetX, targetY)

        const withAnchor = {
            top: initialCoordinates.top - anchorY,
            left: initialCoordinates.left - anchorX,
        }

        divRef.style.top = `${withAnchor.top}px`
        divRef.style.left = `${withAnchor.left}px`
    }, [
        divRef,
        anchorX,
        anchorY,
        targetX,
        targetY,
        width,
        height,
        convertToGlobalCoordinates,
    ])

    if (!divRef) {
        return null
    }

    return ReactDOM.createPortal(children, divRef)
}

export default Tooltip
