import React from 'react'
import { SliderProps } from '@apphiveio/controlsmanager/types/RenderComponents'
import { Wrapper, Input } from './style'

const ControlSlider: React.FC<SliderProps> = ({
    style,
    min,
    max,
    value,
    step,
    thumbColor,
    selectionColor,
    blankColor,
    onValueChanged,
}) => (
    <Wrapper style={style as React.CSSProperties}>
        <Input
            type="range"
            min={min}
            max={max}
            step={step}
            value={value || 0}
            selectionColor={selectionColor}
            thumbColor={thumbColor}
            blankColor={blankColor}
            onChange={(e) => {
                const newValue = Number(e.target.value)
                if (onValueChanged) {
                    onValueChanged(newValue)
                }
            }}
        />
    </Wrapper>
)

export default ControlSlider
