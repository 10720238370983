import { useEffect, useContext } from 'react'
import { AppAtomRenders } from '@apphiveio/controlsmanager/types/RenderComponents'
import ControlMapContext from '../ControlMapView/controlMapContext'

const ControlMapCircle: AppAtomRenders['MapCircle'] = ({
    center,
    radius,
    strokeWidth,
    strokeColor,
    fillColor,
}) => {
    const { googleMap } = useContext(ControlMapContext)

    useEffect(() => {
        const circle = new google.maps.Circle({
            strokeColor,
            strokeWeight: strokeWidth,
            fillColor,
            radius,
            center: {
                lat: center.latitude,
                lng: center.longitude,
            },
        })

        if (googleMap) {
            circle.setMap(googleMap)
        }

        return () => {
            circle.setMap(null)
        }
    }, [center, radius, fillColor, googleMap, strokeColor, strokeWidth])

    return null
}

export default ControlMapCircle
