import React, { useCallback, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { executeAppProcess } from '@apphiveio/controlsmanager/applicationFunctionality/appProcessLauncher'
import appStateSelectBOAppProcesses from '@apphiveio/controlsmanager/shared/appStateSelectBOAppProcesses'
import AppStateStoreProvider from '@apphiveio/controlsmanager/components/AppStateStoreProvider'
import AppRenderer from '@apphiveio/controlsmanager/AppRenderer'
import { Processor } from '@apphiveio/controlsmanager/types/Runtime'
import { DEFINITION_PROP_NAMES } from '@apphiveio/controlsmanager/types/General'
import useWindowResizeEffect from 'hooks/useWindowResizeEffect'
import { ApphiveRendererState } from 'store/reducers'
import WebappContext from 'components/organisms/WebappContext'
import webappFunctions from 'components/organisms/WebappContext/webappFunctions'
import type { AppStateStore } from '@apphiveio/controlsmanager/hooks/useAppStateStore'
import { Frame, PreviewerFrame } from './style'

type ScreenDimentions = {
    width: number;
    height: number;
}

type Props = {
    appStateStore: AppStateStore;
    processor: Processor;
    onRequestRestart?: () => void;
}

const AppBooter: React.VFC<Props> = ({ appStateStore, processor, onRequestRestart }) => {
    const [dimentions, setDimentions] = useState<ScreenDimentions | undefined>(undefined)
    const [executeAppProcessAlias, setExecuteAppProcessAlias] = useState<string>()

    const bottomMenuContext = useSelector((
        state: ApphiveRendererState,
    ) => state.appState.bottomMenuFlagsByPage)
    const sideMenuContext = useSelector((
        state: ApphiveRendererState,
    ) => state.appState.sideMenuContext)
    const alertContext = useSelector((
        state: ApphiveRendererState,
    ) => state.appState.openAlert)
    const confirmAlertContext = useSelector((
        state: ApphiveRendererState,
    ) => state.appState.openConfirmAlert)
    const inputDialogContext = useSelector((
        state: ApphiveRendererState,
    ) => state.appState.openInputDialog)

    const calculateSize = useCallback(() => {
        const windowWidth = window.innerWidth
        const windowHeight = window.innerHeight

        if (windowWidth > windowHeight) {
            setDimentions({
                width: 339,
                height: 639,
            })
        } else {
            setDimentions({
                width: windowWidth,
                height: windowHeight,
            })
        }
    }, [])

    useWindowResizeEffect(calculateSize)

    useEffect(() => {
        if (!appStateStore) {
            return
        }

        const urlDecode = decodeURI(window.location.pathname.replace(/^\/|\/$/g, '')).split('/')
        const appProcesses = appStateSelectBOAppProcesses(appStateStore.getState())
        if (!urlDecode.length || !appProcesses) {
            return
        }

        const appProcessAlias = urlDecode[0]
        if (executeAppProcessAlias === appProcessAlias) {
            return
        }

        const appProcess = Object.entries(appProcesses)
            .find(([, value]) => (
                value[DEFINITION_PROP_NAMES.URL_SETTINGS]?.urlAlias === appProcessAlias
            ))

        if (!appProcess) {
            return
        }

        const urlDecodeParams = urlDecode.slice(1)
        const appProcessUrlFormat = appProcess[1][DEFINITION_PROP_NAMES.URL_SETTINGS]?.urlParams?.split('/') || []

        let urlCorrectFormat = true
        appProcessUrlFormat.forEach((param, index) => {
            if (!param.startsWith(':') && param !== urlDecodeParams[index]) {
                urlCorrectFormat = false
            }
        })

        if (!urlCorrectFormat) {
            return
        }

        const urlQueryParams = Array.from(new URLSearchParams(window.location.search).entries())
            .reduce<Partial<Record<string, string>>>((accum, [key, value]) => ({
                ...accum,
                [key]: value,
            }), {})

        const entryVars = appProcessUrlFormat.reduce((accum, param, index) => {
            if (param.startsWith(':')) {
                return {
                    ...accum,
                    [param.slice(1)]: urlDecodeParams[index],
                }
            }
            return accum
        }, urlQueryParams)

        executeAppProcess(webappFunctions)({
            store: appStateStore,
            functionsFromAppProcessCallbacksCallbacks: undefined,
            processor,
            entryVars,
            appProcessId: appProcess[0],
        })
        setExecuteAppProcessAlias(appProcessAlias)
    }, [processor, executeAppProcessAlias, appStateStore])

    if (!dimentions) {
        return null
    }

    return (
        <AppStateStoreProvider store={appStateStore}>
            <Frame>
                <PreviewerFrame>
                    <WebappContext
                        windowHeight={dimentions.height}
                        windowWidth={dimentions.width}
                        processor={processor}
                    >
                        <AppRenderer
                            sideMenuContext={sideMenuContext}
                            bottomMenuContext={bottomMenuContext}
                            alertContext={alertContext}
                            confirmAlertContext={confirmAlertContext}
                            inputDialogContext={inputDialogContext}
                            onRequestRestart={onRequestRestart}
                        />
                    </WebappContext>
                </PreviewerFrame>
            </Frame>
        </AppStateStoreProvider>
    )
}

export default AppBooter
