import React from 'react'
import { AppAtomRenders } from '@apphiveio/controlsmanager/types/RenderComponents'
import {
    SkeletonContentWrapper,
} from './style'

const ControlSkeletonContent: AppAtomRenders['SkeletonContent'] = ({
    isLoading,
    layout,
}) => (
    <SkeletonContentWrapper
        isLoading={isLoading}
        layout={layout}
    />
)

export default ControlSkeletonContent
