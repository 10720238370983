export default {
    frame: {
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#1a2b3459',
    },
    container: {
        width: 300,
        flexDirection: 'column',
        backgroundColor: 'white',
        overflow: 'hidden',
        borderRadius: 3,
        padding: 12,
    },
    header: {
        margin: 12,
    },
    dateTimeHeader: {
        margin: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 10,
    },
    title: {
        fontWeight: 'bold',
        fontSize: 18,
        color: 'gray',
        width: '100%',
        height: 35,
        display: 'flex',
        textAlign: 'center',
        cursor: 'pointer',
        alignItems: 'center',
        justifyContent: 'center',
        marginHorizontal: 20,
    },
    modeSelected: {
        borderColor: 'darkgray',
        color: 'black',
        borderStyle: 'solid',
        borderBottomWidth: 1,
    },
    description: {
        color: '#33383D',
        fontSize: 16,
        marginTop: 10,
    },
    content: {
        backgroundColor: 'white',
        alignItems: 'center',
    },
    timePickerfooter: {
        backgroundColor: '#f4f4f4',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        borderBottomLeftRadius: 3,
        borderBottomRightRadius: 3,
        marginLeft: 8,
        marginRight: 8,
    },
    footer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    button: {
        justifyContent: 'center',
        alignItems: 'center',
        marginHorizontal: 10,
        marginVertical: 15,
    },
    buttonText: {
        textAlign: 'center',
        fontSize: 14,
        color: '#008080',
        fontWeight: '500',
    },
}
