import React from 'react'
import { AppAtomRenders } from '@apphiveio/controlsmanager/types/RenderComponents'
import SvgIcon from '@apphiveio/controlsmanager/components/SvgIcon'
import {
    CheckboxContainer,
    StyledCheckbox,
} from './style'

const ControlCheckBox: AppAtomRenders['CheckBox'] = ({
    isChecked,
    checkColor,
    onClick,
}) => (
    <CheckboxContainer>
        <StyledCheckbox
            checkColor={checkColor || '#26a69a'}
            onClick={onClick}
        >
            {isChecked && (
                <SvgIcon
                    family="FontAwesome"
                    name="check-square"
                    color="gray"
                    size={12}
                />
            )}
        </StyledCheckbox>
    </CheckboxContainer>
)

export default ControlCheckBox
