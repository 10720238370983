import { useEffect, useState } from 'react'

const useCurrentLanguageEffect = () => {
    const [language, setLanguage] = useState(navigator.language)

    useEffect(() => {
        const listener = () => {
            setLanguage(navigator.language)
        }
        window.addEventListener('languagechange', listener)

        return () => {
            window.removeEventListener('languagechange', listener)
        }
    }, [])

    return language
}

export default useCurrentLanguageEffect
