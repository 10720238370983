import { createContext } from 'react'

type ControlMapContextType = {
    infoWindow: google.maps.InfoWindow;
    googleMap: google.maps.Map | undefined;
}

const ControlMapContext: Partial<ControlMapContextType> = {}

export default createContext(ControlMapContext)
