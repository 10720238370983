import { useEffect, useContext } from 'react'
import { AppAtomRenders } from '@apphiveio/controlsmanager/types/RenderComponents'
import ControlMapContext from '../ControlMapView/controlMapContext'

const ControlMapPolyLine: AppAtomRenders['MapPolyLine'] = ({
    isRoute,
    coordinates,
    strokeWidth,
    strokeColor,
}) => {
    const { googleMap } = useContext(ControlMapContext)

    useEffect(() => {
        let directions: google.maps.DirectionsRenderer | null = null
        let polilyne: google.maps.Polyline | null = null

        if (isRoute) {
            directions = new google.maps.DirectionsRenderer({
                suppressMarkers: true,
                polylineOptions: {
                    strokeColor,
                    strokeWeight: strokeWidth,
                },
            })

            const directionsService = new google.maps.DirectionsService()
            directionsService.route({
                origin: { lat: coordinates[0].latitude, lng: coordinates[0].longitude },
                destination: { lat: coordinates[1].latitude, lng: coordinates[1].longitude },
                travelMode: google.maps.TravelMode.DRIVING,
            }, (response, status) => {
                if (status === google.maps.DirectionsStatus.OK) {
                    directions?.setDirections(response)
                }
            })

            if (googleMap) {
                directions.setMap(googleMap)
            }
        }

        if (!isRoute) {
            polilyne = new google.maps.Polyline({
                path: coordinates.map((coordinate) => ({
                    lat: coordinate.latitude,
                    lng: coordinate.longitude,
                })),
                geodesic: true,
                strokeColor,
                strokeWeight: strokeWidth,
            })

            if (googleMap) {
                polilyne.setMap(googleMap)
            }
        }

        return () => {
            directions?.setMap(null)
            polilyne?.setMap(null)
        }
    }, [coordinates, googleMap, isRoute, strokeColor, strokeWidth])

    return null
}

export default ControlMapPolyLine
