import { AppAtomRenders } from '@apphiveio/controlsmanager/types/RenderComponents'
import { Text, TextInput } from 'react-native-web'
import AttachedTooltip, { ATTACHEDTOOLTIP_POINTS, TOOLTIP_OVERFLOW_DIRECTION } from 'components/molecules/AttachedTooltip'
import React, { useState } from 'react'
import PlacesAutocomplete, { getLatLng, geocodeByAddress, Suggestion } from 'react-places-autocomplete'
import { GooglePlaceDetail } from '@apphiveio/controlsmanager/types/PlacesAuto'
import { ListItem, ListView } from './style'

const ControlPlacesAutoComplete: AppAtomRenders['GooglePlacesAutocomplete'] = ({
    styles,
    placeholder,
    query,
    onPress,
    onBlur,
}) => {
    const [address, setAddress] = useState('')
    const [parentDirection, setParentDirection] = useState(ATTACHEDTOOLTIP_POINTS.BOTTOM_LEFT)
    const [anchorDirection, setAnchorDirection] = useState(ATTACHEDTOOLTIP_POINTS.TOP_LEFT)

    const handleChange = (value: string) => {
        setAddress(value)
    }
    const handleSelect = (value: string) => {
        setAddress(value)
    }
    const blurHandler = () => {
        if (onBlur) {
            onBlur()
        }
    }

    const handleOnPress = async (suggestion: Suggestion) => {
        if (!onPress) return
        const result = await geocodeByAddress(suggestion.description)
        const placeInformation = result[0]
        const coordinates = await getLatLng(placeInformation)
        const addresComponents = placeInformation.address_components as GooglePlaceDetail['address_components']
        const adressType = suggestion.types.map((type) => type) as []
        onPress({
            description: suggestion.description,
            id: suggestion.id,
            matched_substrings: suggestion.matchedSubstrings,
            place_id: suggestion.placeId,
            reference: '',
            structured_formatting: {
                main_text: suggestion.formattedSuggestion.mainText,
                secondary_text: suggestion.formattedSuggestion.secondaryText,
                main_text_matched_substrings: [],
                secondary_text_matched_substrings: [],
                terms: suggestion.terms,
                types: adressType,
            },
        }, {
            address_components: addresComponents,
            adr_address: '',
            formatted_address: placeInformation.formatted_address,
            geometry: {
                location: { lat: coordinates.lat, lng: coordinates.lng },
                viewport: {
                    northeast: { lat: 0, lng: 0 },
                    southwest: { lat: 0, lng: 0 },
                },
            },
            icon: '',
            id: suggestion.id,
            name: suggestion.formattedSuggestion.mainText,
            place_id: suggestion.placeId,
            reference: '',
            scope: 'GOOGLE',
            types: adressType,
            url: '',
            utc_offset: 0,
            vicinity: suggestion.formattedSuggestion.mainText,
        })
    }
    const searchOptions = query.components ? {
        componentRestrictions: {
            country: query.components.replaceAll('country:', '').split('|'),
        },
    } : undefined
    return (
        <PlacesAutocomplete
            value={address}
            onChange={handleChange}
            onSelect={handleSelect}
            searchOptions={searchOptions}
        >
            {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                <div style={styles?.textInputContainer as React.CSSProperties}>
                    <AttachedTooltip
                        width="inherited"
                        height={suggestions.length ? 200 : 0}
                        parentPoint={parentDirection}
                        anchorPoint={anchorDirection}
                        renderParent={((parentRef) => {
                            const inputProps = getInputProps()
                            return (
                                <div
                                    ref={parentRef}
                                    style={styles?.textInput as React.CSSProperties}
                                >
                                    <TextInput
                                        placeholder={placeholder || undefined}
                                        style={styles?.textInput}
                                        onBlur={blurHandler}
                                        onChange={inputProps.onChange}
                                        role={inputProps.role}
                                        autoComplete={inputProps.autoComplete}
                                        aria-autocomplete={inputProps['aria-autocomplete']}
                                        aria-expanded={inputProps['aria-expanded']}
                                        aria-activedescendant={inputProps['aria-activedescendant']}
                                        disabled={inputProps.disabled}
                                        onKeyDown={inputProps.onKeyDown}
                                        value={inputProps.value || undefined}
                                        placeholderTextColor={styles?.textInput
                                            ?.placeholderTextColor}
                                    />
                                </div>
                            )
                        }
                        )}
                        onScreenOverflow={(direction) => {
                            if (direction === TOOLTIP_OVERFLOW_DIRECTION.BOTTOM) {
                                setParentDirection(ATTACHEDTOOLTIP_POINTS.TOP_LEFT)
                                setAnchorDirection(ATTACHEDTOOLTIP_POINTS.BOTTOM_LEFT)
                            }
                        }}
                    >
                        <ListView>
                            {suggestions.map((suggestion) => {
                                const injectedSugestionsProps = getSuggestionItemProps(suggestion)
                                return (
                                    <ListItem
                                        key={injectedSugestionsProps.key}
                                        onMouseEnter={injectedSugestionsProps.onMouseEnter}
                                        onMouseLeave={injectedSugestionsProps.onMouseLeave}
                                        onMouseDown={injectedSugestionsProps.onMouseDown}
                                        onMouseUp={injectedSugestionsProps.onMouseUp}
                                        onTouchStart={injectedSugestionsProps.onTouchStart}
                                        onTouchEnd={injectedSugestionsProps.onTouchEnd}
                                        onClick={(event) => {
                                            injectedSugestionsProps.onClick(event)
                                            handleOnPress(suggestion)
                                        }}
                                    >
                                        <Text>
                                            {suggestion.description}
                                        </Text>
                                    </ListItem>
                                )
                            })}
                        </ListView>
                    </AttachedTooltip>
                </div>
            )}
        </PlacesAutocomplete>
    )
}

export default ControlPlacesAutoComplete
