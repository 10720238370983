import ControlRendererContext, {
    ControlRendererContextShape,
} from '@apphiveio/controlsmanager/ControlRendererContext'
import { Processor } from '@apphiveio/controlsmanager/types/Runtime'
import { AppAtomRenders } from '@apphiveio/controlsmanager/types/RenderComponents'
import controlRenderDefaultWrapper from '@apphiveio/controlsmanager/shared/controlRenderDefaultWrapper'
import React, { useEffect, useMemo } from 'react'
import {
    View,
    Text,
    TouchableOpacity,
    FlatList,
    Modal,
    SectionList,
    Switch,
    Animated,
    ScrollView,
} from 'react-native-web'
import SvgIcon from '@apphiveio/controlsmanager/components/SvgIcon'

import { odCreatorApp } from 'firebaseConnection'
import useCurrentLanguageEffect from 'hooks/useCurrentLanguageEffect'
import ControlSvgImage from 'components/atoms/ControlSvgImage'
import ControlLottie from 'components/atoms/ControlLottie'
import ControlImageBackground from 'components/atoms/ControlImageBackground'
import ControImage from 'components/atoms/ControImage'
import ControlSlider from 'components/atoms/ControlSlider'
import ControlCheckBox from 'components/atoms/ControlCheckBox'
import ControlPicker from 'components/atoms/ControlPicker'
import ControlCalendar from 'components/atoms/ControlCalendar'
import ControlDatePicker from 'components/atoms/ControlDatePicker'
import ControlCameraView from 'components/atoms/ControlCameraView'
import ControlVideoView from 'components/atoms/ControlVideoView'
import ControlWebView from 'components/atoms/ControlWebView'
import ControlMapView from 'components/atoms/ControlMapView'
import ControlMapMarker from 'components/atoms/ControlMapMarker'
import ControlAnimatedFlatList from 'components/atoms/ControlAnimatedFlatList'
import ControlPlacesAutoComplete from 'components/atoms/ControlPlacesAutoComplete'
import ControlSkeletonContent from 'components/atoms/ControlSkeletonContent'
import ControlGraphic from 'components/atoms/ControlGraphic'
import WebappMapsWrapper from 'components/WebappMapsWrapper'
import ControlVideo from 'components/atoms/ControlVideo'
import ControlTextInput from 'components/atoms/ControlTextInput'
import ControlMapPolygon from 'components/atoms/ControlMapPolygon'
import ControlMapCircle from 'components/atoms/ControlMapCircle'
import ControlMapPolyLine from 'components/atoms/ControlMapPolyLine'
import imageStaticSourcesByFileName from '../../../imageStaticSourcesByFileName'

export const WEBAPP_DIMENTIONS = {
    windowWidth: 0,
    windowHeight: 0,
}

const Render: AppAtomRenders = {
    StripeCardField: () => null,
    ControlNotFound: () => null,
    View,
    Text,
    TouchableOpacity,
    Image: ControImage,
    StatusBar: (() => <div />),
    BannerAd: () => null,
    Calendar: ControlCalendar,
    CameraView: ControlCameraView,
    CheckBox: ControlCheckBox,
    DatePicker: ControlDatePicker,
    FlatList,
    GooglePlacesAutocomplete: ControlPlacesAutoComplete,
    ImageBackground: ControlImageBackground,
    KeyboardAvoidingView: View,
    Lottie: ControlLottie,
    MapMarker: ControlMapMarker,
    Modal,
    PickerView: ControlPicker,
    RotationLock: () => null,
    SafeAreaView: View,
    SkeletonContent: ControlSkeletonContent,
    ScrollView,
    Slider: ControlSlider,
    Video: ControlVideo,
    VideoView: ControlVideoView,
    WebView: ControlWebView,
    AnimatedImage: Animated.Image,
    AnimatedFlatList: ControlAnimatedFlatList,
    AnimatedView: Animated.View,
    Graphic: ControlGraphic,
    MapCircle: ControlMapCircle,
    MapPolyLine: ControlMapPolyLine,
    MapPolygon: ControlMapPolygon,
    MapView: ControlMapView,
    SectionList,
    SvgIcon,
    SvgImage: ControlSvgImage,
    Switch,
    TextInput: ControlTextInput,
    JitsiMeet: () => null,
    ImageViewer: () => null,
    InterstitialAd: () => null,
}

type Props = {
    windowHeight: number;
    windowWidth: number;
    children: React.ReactNode;
    processor: Processor;
}

const WebappContext: React.FC<Props> = ({
    windowHeight,
    windowWidth,
    children,
    processor,
}) => {
    WEBAPP_DIMENTIONS.windowWidth = windowWidth
    WEBAPP_DIMENTIONS.windowHeight = windowHeight
    const language = useCurrentLanguageEffect()

    const controlRendererContext = useMemo<ControlRendererContextShape | undefined>(() => {
        if (!processor) {
            return undefined
        }
        return {
            imageStaticSourcesByFileName,
            renderControl: controlRenderDefaultWrapper,
            functions: {
                listenerBackHandler: undefined,
                processor,
            },
            locale: language,
            dimentions: {
                window: {
                    height: windowHeight,
                    width: windowWidth,
                },
                screen: {
                    height: windowHeight,
                    width: windowWidth,
                },
                statusBarHeight: 0,
            },
            Animated, // eslint-disable-line
            Render,
        }
    }, [
        language,
        processor,
        windowHeight,
        windowWidth,
    ])

    useEffect(() => {
        let unsubscribe: () => void = () => undefined

        try {
            unsubscribe = odCreatorApp.messaging().onMessage((message) => {
                // eslint-disable-next-line no-alert
                window.alert(`
                    ${message.notification?.title}
                    ${message.notification?.body}
                `)
            })
        } catch {
            // NO ACTION
        }

        return () => {
            unsubscribe()
        }
    }, [])

    if (!controlRendererContext) {
        return null
    }

    return (
        <ControlRendererContext.Provider
            value={controlRendererContext}
        >
            <WebappMapsWrapper>
                {children}
            </WebappMapsWrapper>
        </ControlRendererContext.Provider>
    )
}

export default WebappContext
