import {
    ApolloClient,
    createHttpLink,
    InMemoryCache,
    ApolloError,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import clientconfig from 'clientconfig'
import getValueByPathFromUnknown from '@apphiveio/controlsmanager/shared/getValueByPathFromUnknown'
import valueIsString from '@apphiveio/controlsmanager/typeAssertions/valueIsString'
import windowAlert from 'shared/windowAlert'
import { odCreatorApp } from 'firebaseConnection'

export const defaultPollInterval = 600000 // 10 minutes

export const onApolloError = (updateError: ApolloError) => {
    const directErrorMessage = getValueByPathFromUnknown(updateError, ['message'])

    if (valueIsString(directErrorMessage) && directErrorMessage.length > 0) {
        windowAlert('Warning', directErrorMessage)
        return
    }
    windowAlert('Error', 'Unknown error from our servers')
}

const enableDevMode = process.env.NODE_ENV === 'development'

const httpLink = createHttpLink({
    uri: enableDevMode ? 'http://localhost:4141/gql/' : `${clientconfig.getServicesUrls().graphql}/gql`,
})

const authLink = setContext(async (_, { headers }) => {
    const { currentUser } = odCreatorApp.auth()
    const userToken = currentUser ? (await currentUser.getIdToken()) : undefined

    if (userToken) {
        return {
            headers: {
                ...headers,
                authorization: `Bearer ${userToken}`,
            },
        }
    }

    return headers
})

const cache = new InMemoryCache({
    typePolicies: {
        FinancesPlanRecord: {
            keyFields: ['id', 'periodId'],
        },
    },
})

export default new ApolloClient({
    link: authLink.concat(httpLink),
    cache,
})
