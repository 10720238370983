import styled from 'styled-components/macro'

export const VideoWrapper = styled.div`
    width: 100%;
    height: 100%;
`

export const Video = styled.video`
    object-fit: cover;
    width: 100%;
    height: 100%;
`

export const PlaceHolderImage = styled.img`
    width: 100%;
    height: 100%;
`
