import styled from 'styled-components/macro'

export const ListView = styled.ul`
    background-color: white;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
`

export const ListItem = styled.li`
    list-style: none;
    padding: 12px 16px;
    cursor: pointer;

    &:hover {
        background-color: #f8f9fa;
    }
`
