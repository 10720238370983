import styled, { keyframes } from 'styled-components/macro'

const loading = keyframes`
    from {
        left:-200px;
    }
    to {
        left:100%;
    }
`

type LayoutStyle = {
    height?: number | string;
    width?: number | string;
}

export const SkeletonContentWrapper = styled.div<{
    layout?: LayoutStyle;
    isLoading: boolean;
}>`
    padding: 0
    margin: 0;
    height: ${({
        layout,
    }) => {
        if (layout?.height) {
            return `${layout?.height}px`
        }
        return '100%'
    }};
    width: ${({
        layout,
    }) => {
        if (layout?.width) {
            return `${layout?.width}px`
        }
        return '100%'
    }};
    overflow: hidden;
    background: #E1E9EE;
    border: 1px solid;
    border-color: #E1E9EE;
    border-radius: 0px; 
    display: block;
    position: relative;

    &&::before{
        content: '';
        display: block;
        position: absolute;
        left: -200px;
        top: 0;
        height: 100%;
        width: 200px;
        background: linear-gradient(to right, #E1E9EE 0%, rgba(255,255,255,0.4) 50%, #E1E9EE 100%);
        animation: ${loading} 800ms linear infinite;
    }
`
