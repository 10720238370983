import styled from 'styled-components/macro'

export const Input = styled.input<{
    thumbColor?: string;
    selectionColor?: string;
    blankColor?: string;
    value?:number;
}>`
    appearance: none;
    width: 100%;
    outline: 0;
    height: 8px;
    border-radius: 40px;
    background: ${({ value, blankColor, selectionColor }) => `linear-gradient(to right, ${selectionColor} 0%, ${selectionColor} ${value}%, ${blankColor} ${value}%, ${blankColor} 100%);`}
    ::-webkit-slider-thumb {
        appearance: none;
        transform: translateY(-0.2px);
        width: 15px;
        height: 15px;
        border-radius: 50%; 
        background: ${({ thumbColor }) => thumbColor};
        cursor: pointer;
    }
`

export const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`
