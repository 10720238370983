import { useEffect } from 'react'

const useWindowScrollEffect = (listener: () => void) => {
    useEffect(() => {
        window.addEventListener('scroll', listener, true)
        return () => {
            window.removeEventListener('scroll', listener, true)
        }
    }, [listener])
}

export default useWindowScrollEffect
