import { useEffect, useContext } from 'react'
import { AppAtomRenders } from '@apphiveio/controlsmanager/types/RenderComponents'
import ControlMapContext from '../ControlMapView/controlMapContext'

const ControlMapPolygon: AppAtomRenders['MapPolygon'] = ({
    coordinates,
    fillColor,
    strokeWidth,
    strokeColor,
}) => {
    const { googleMap } = useContext(ControlMapContext)

    useEffect(() => {
        const polygon = new google.maps.Polygon({
            strokeColor,
            strokeWeight: strokeWidth,
            fillColor,
            fillOpacity: 1,
            paths: coordinates.map((coordinate) => ({
                lat: coordinate.latitude,
                lng: coordinate.longitude,
            })),
        })

        if (googleMap) {
            polygon.setMap(googleMap)
        }

        return () => {
            polygon.setMap(null)
        }
    }, [coordinates, fillColor, googleMap, strokeColor, strokeWidth])

    return null
}

export default ControlMapPolygon
