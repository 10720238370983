import { createStore, Store } from 'redux'
import rootReducer, { ApphiveRendererState } from 'store/reducers'
import { ApphiveRendererAction } from 'store/actionTypes'

export type ApphiveRendererStore = Store<ApphiveRendererState, ApphiveRendererAction>

const store: ApphiveRendererStore = createStore(
    rootReducer,
)

export default store
