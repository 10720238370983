import React from 'react'
import { AppAtomRenders } from '@apphiveio/controlsmanager/types/RenderComponents'
import { Iframe, Wrapper } from './style'

const ControlWebView: AppAtomRenders['WebView'] = ({
    style: webViewStyle,
    webConfig: {
        href,
    },
}) => (
    <Wrapper style={webViewStyle}>
        {href && (
            <Iframe
                title={href}
                src={href}
            />
        )}
    </Wrapper>
)

export default ControlWebView
