import { useCallback } from 'react'
import { LatLng } from '@apphiveio/controlsmanager/types/Maps'

type AddMarkerArgs = {
    coordinate: LatLng;
    icon?: string;
    title?: string;
    description?: string;
    handler?: () => void;
}

type InfoWindowArgs = {
    coordinate: LatLng;
    title?: string;
    description?: string;
}

const useGoogleMapMarker = (googleMap?: google.maps.Map) => {
    const infoWindow = useCallback(({
        coordinate,
        title,
        description,
    }: InfoWindowArgs) => {
        if (!googleMap) {
            return
        }
        const position = new google.maps.LatLng(coordinate.latitude, coordinate.longitude)
        const info = new google.maps.InfoWindow({
            position,
            content: `
                <div>
                    <p><strong>${title || ''}</strong></p>
                    <p>${description || ''}</p>
                </div>
            `,
        })
        info.open(googleMap)
    }, [googleMap])

    const addMarker = useCallback(({
        coordinate,
        icon,
        title,
        description,
        handler,
    }: AddMarkerArgs) => {
        if (!googleMap) {
            return
        }
        const position = new google.maps.LatLng(coordinate.latitude, coordinate.longitude)
        const marker = new google.maps.Marker({
            draggable: false,
            cursor: 'pointer',
            icon: icon || null,
            position,
            map: googleMap,
        })
        marker.addListener('click', () => {
            if (handler) {
                handler()
            }
            if (title) {
                infoWindow({
                    coordinate,
                    title,
                    description,
                })
            }
        })
    }, [infoWindow, googleMap])

    return {
        addMarker,
    }
}

export default useGoogleMapMarker
