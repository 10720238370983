import { gql } from '@apollo/client'
import getValueByPathFromUnknown from '@apphiveio/controlsmanager/shared/getValueByPathFromUnknown'
import apolloClient, { onApolloError } from 'providers/apolloClient'

export const AUTH_USER_CREATE = gql`mutation setAuthUser(
    $name: String!
    $email: String!
    $password: String
    $phone: String!
    $ip: String
    $platform: String!
    $typeLogIn: String!
    $uid: String
    $locale: String
){
    authUserCreate(
        name: $name
        email: $email
        password: $password
        phone: $phone
        ip: $ip
        platform: $platform
        typeLogIn: $typeLogIn
        uid: $uid
        locale: $locale
    )
}`

export const AUTH_USER_UPDATE_LOCALE = gql`mutation setAuthUserUpdateLocale(
    $locale: String!
){
    authUserUpdateLocale(
        locale: $locale
    ) {
        id
        localeId
    }
}`

type AuthUserCreateVars = {
    name: string;
    email: string;
    password?: string;
    phone: string;
    ip?: string;
    platform: string;
    typeLogIn: string;
    uid?: string;
    locale?: string;
}

type AuthUserLocaleVars = {
    locale: string;
}

export const authUserCreate = () => (
    async (props: AuthUserCreateVars) => {
        try {
            const result = await apolloClient.mutate<unknown, AuthUserCreateVars>({
                variables: props,
                mutation: AUTH_USER_CREATE,
            })
            return result
        } catch (error) {
            onApolloError({
                message: `${getValueByPathFromUnknown(error, ['message'])}`,
                graphQLErrors: [],
                clientErrors: [],
                networkError: null,
                extraInfo: undefined,
                name: '',
            })
            return undefined
        }
    }
)

export const setAuthUserLocale = () => (
    async (props: AuthUserLocaleVars) => {
        try {
            const result = await apolloClient.mutate<unknown, AuthUserLocaleVars>({
                variables: props,
                mutation: AUTH_USER_UPDATE_LOCALE,
            })
            return result
        } catch (error) {
            onApolloError({
                message: `${getValueByPathFromUnknown(error, ['message'])}`,
                graphQLErrors: [],
                clientErrors: [],
                networkError: null,
                extraInfo: undefined,
                name: '',
            })
            return undefined
        }
    }
)

export default authUserCreate
