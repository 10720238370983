const audioRecorderCoreBuilder = () => {
    let mediaRecorder: MediaRecorder
    const audioChunks: Blob[] = []

    const onStartRecord = async () => {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true })

        mediaRecorder = new MediaRecorder(stream)

        mediaRecorder.ondataavailable = (event) => {
            if (event.data.size > 0) {
                audioChunks.push(event.data)
            }
        }

        mediaRecorder.start()
    }

    const onStopRecord = () => new Promise<string>((resolve, reject) => {
        if (!mediaRecorder || mediaRecorder.state !== 'recording') {
            reject(new Error('No recordings started'))
        }

        mediaRecorder.onstop = () => {
            const audioBlob = new Blob(audioChunks, { type: 'audio/wav' })
            const audioUrl = URL.createObjectURL(audioBlob)
            resolve(audioUrl)
        }

        mediaRecorder.stop()
    })

    return { onStartRecord, onStopRecord }
}

export default audioRecorderCoreBuilder
