import store from 'store'
import ReduxConstants from 'store/constants'
import { AppUser } from '@apphiveio/controlsmanager/types/Firebase'

export const setRendererCurrentUser = (user: AppUser | null) => {
    store.dispatch({
        type: ReduxConstants.SET_RENDERER_CURRENT_USER,
        payload: user,
    })
}
