import React, { useEffect, useState } from 'react'
import appStateBuildGetStoreHook from '@apphiveio/controlsmanager/shared/appStateBuildGetStoreHook'
import { Processor } from '@apphiveio/controlsmanager/types/Runtime'
import AppBooter from 'components/organisms/AppBooter'
import buildApplicationProcessor from 'shared/buildApplicationProcessor'
import { initialAppState } from 'store/reducers/previewer'
import type { BuildOrders } from '@apphiveio/controlsmanager/types/Controls'
import webappFunctions from './WebappContext/webappFunctions'
import ClientAppSplashScreen from './ClientAppSplashScreen'

const useStore = appStateBuildGetStoreHook({
    getInitialBO: async () => initialAppState.buildOrders as unknown as BuildOrders,
    functions: {
        areEnabled: true,
        definitions: webappFunctions,
    },
    interfaces: {
        localStorage: {
            getItem: async (key) => window.localStorage.getItem(key),
            removeItem: async (key) => window.localStorage.removeItem(key),
            setItem: async (key, value) => window.localStorage.setItem(key, value),
        },
    },
})

const projectId = initialAppState.projectId ?? ''
const appId = initialAppState.appId ?? ''

const ProcessorAppBooter = () => {
    const [processor, setProcessor] = useState<Processor | undefined>(undefined)
    const appStateStore = useStore({
        appId,
        projectId,
    })

    useEffect(() => {
        if (!appStateStore) {
            return
        }
        buildApplicationProcessor(appStateStore).then(setProcessor)
    }, [appStateStore])

    useEffect(() => () => {
        if (!processor) {
            return
        }
        processor.destroy()
    }, [processor])

    if (!processor || !appStateStore) {
        return <ClientAppSplashScreen />
    }

    return (
        <AppBooter
            appStateStore={appStateStore}
            processor={processor}
        />
    )
}

export default ProcessorAppBooter
