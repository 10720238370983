import firebase from 'firebase/compat/app'
import 'firebase/compat/functions'
import 'firebase/compat/database'
import 'firebase/compat/auth'
import 'firebase/compat/storage'
import 'firebase/compat/messaging'
import clientconfig from 'clientconfig'

export const getFirebaseApp = (
    appName: string,
) => {
    try {
        return firebase.app(appName)
    } catch {
        const firebaseConfig = clientconfig.getFirebaseWebClient()
        if (!firebaseConfig) {
            throw new Error('Should always have a firebase config')
        }
        return firebase.initializeApp(firebaseConfig, appName)
    }
}

export const odCreatorApp = getFirebaseApp('main')
