import { User } from '@firebase/auth-types'
import { RendererAuthAction } from 'store/actionTypes/auth'
import ReduxConstants from 'store/constants'

export type AuthState = {
    rendererCurrentUser: User | null;
}

const defaultState: AuthState = {
    rendererCurrentUser: null,
}

export default (paramState: AuthState | undefined, action: RendererAuthAction) => {
    const state = paramState || defaultState
    switch (action.type) {
    case ReduxConstants.SET_RENDERER_CURRENT_USER:
        return {
            rendererCurrentUser: action.payload,
        }
    default:
        return state
    }
}
