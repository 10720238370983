import { Callable } from '@apphiveio/controlsmanager/types/Runtime'

const startGeolocationTracking: Callable = async ({
    functionId,
    callbacks,
    executeProcesses,
}) => {
    executeProcesses(
        functionId,
        callbacks.onErrorProcess,
        'Geolocation tracking is not supported on web',
    )
}

export default startGeolocationTracking
