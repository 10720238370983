import React from 'react'
import PreviewerPageWrapper from '@apphiveio/controlsmanager/components/PreviewerPageWrapper'
import PreviewerPageSplash from '@apphiveio/controlsmanager/components/PreviewerPageSplash'

const compiledSplashScreenSource = require('./splashScreen.png').default  // eslint-disable-line
const loadingMessage = 'Loading'
const background = 'primary'
const backgroundColor = undefined

const ClientAppSplashScreen = () => {
    if (compiledSplashScreenSource) {
        return <PreviewerPageWrapper backgroundSource={compiledSplashScreenSource} />
    }

    return (
        <PreviewerPageSplash
            message={loadingMessage}
            background={background}
            backgroundColor={backgroundColor}
        />
    )
}

export default ClientAppSplashScreen
