import React from 'react'

type Props = {
    src?: string;
}

const ControlSvgImage: React.VFC<Props> = ({
    src,
}) => {
    const style = {
        height: '100%',
        width: '100%',
    }
    return (<img src={src} style={style} alt="svg" />)
}

export default ControlSvgImage
