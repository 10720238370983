import React, { useRef, useEffect } from 'react'
import Chart from 'chart.js/auto'
import { AppAtomRenders } from '@apphiveio/controlsmanager/types/RenderComponents'

const typesGraphics = {
    lineChart: 'line',
    barChart: 'bar',
    pieChart: 'pie',
    progressChart: 'doughnut',
    bezierChart: 'line',
} as const

const ControlGraphic: AppAtomRenders['Graphic'] = ({
    typeGraphic,
    pieColors,
    labels,
    data,
    width,
    height,
    style,
}) => {
    const canvasRef = useRef<HTMLCanvasElement>(null)

    useEffect(() => {
        let chart: Chart
        if (canvasRef?.current) {
            chart = new Chart(canvasRef.current, {
                type: typesGraphics[typeGraphic],
                data: {
                    labels: labels || ['empty'],
                    datasets: [{
                        label: '',
                        data: data || [0],
                        backgroundColor: pieColors || (style.color || 'gray'),
                        borderColor: style.color || 'gray',
                        borderWidth: 2,
                        tension: typeGraphic === 'bezierChart' ? 0.5 : undefined,
                    }],
                },
            })
        }
        return () => {
            if (chart) {
                chart.destroy()
            }
        }
    }, [data, labels, style.color, typeGraphic, pieColors])

    return (
        <canvas
            id="chart"
            data-testid="canvas"
            ref={canvasRef}
            width={width}
            height={height}
            style={{ backgroundColor: 'white' }}
        />
    )
}

export default ControlGraphic
