import { gql } from '@apollo/client'
import getValueByPathFromUnknown from '@apphiveio/controlsmanager/shared/getValueByPathFromUnknown'
import apolloClient from 'providers/apolloClient'

type MutationArgs = {
    appId: string;
    appProcessId: string;
}

type Args = {
    appId: string;
    appProcessId: string;
}

const reportCloudFunctionCall = async ({ appId, appProcessId }: Args) => {
    const { data } = await apolloClient.mutate<unknown, MutationArgs>({
        mutation: gql`query ReportCloudExecution($appId: String! $appProcessId: String!) {
            clientAppReportCloudFunctionExecution(appId: $appId, appProcessId: $appProcessId)
        }`,
        variables: { appId, appProcessId },
    })

    const flag = getValueByPathFromUnknown(data, ['clientAppReportCloudFunctionExecution'])

    return !!flag
}

export default reportCloudFunctionCall
