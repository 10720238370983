import { combineReducers } from 'redux'
import { LegacyApplicationState, PreviewerState } from '@apphiveio/controlsmanager/types/Application'
import applicationStateBuildReducer from '@apphiveio/controlsmanager/shared/applicationStateBuildReducer'
import buildPreviewerStateReducer from '@apphiveio/controlsmanager/previewerState/buildPreviewerStateReducer'
import { initialAppState } from 'store/reducers/previewer'
import auth, { AuthState } from './auth'

export type ApphiveRendererState = {
    auth: AuthState;
    appState: LegacyApplicationState;
    previewer: PreviewerState;
}

const rootReducer = combineReducers({
    auth,
    appState: applicationStateBuildReducer(initialAppState),
    previewer: buildPreviewerStateReducer(),
})

export default rootReducer
