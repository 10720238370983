/* eslint-disable react/prop-types */
import React, { useMemo, useState } from 'react'
import { Modal as ModalFrame, Text } from 'react-native-web'
import { AppAtomRenders } from '@apphiveio/controlsmanager/types/RenderComponents'
import SvgIcon from '@apphiveio/controlsmanager/components/SvgIcon'
import {
    Picker, Modal, ModalItem, Frame, Container,
} from './style'

const ControlPicker: AppAtomRenders['PickerView'] = ({
    value,
    items,
    placeholder,
    style,
    arrowsEnabled,
    onChange,
}) => {
    const [isVisible, setIsVisible] = useState(false)

    const placeHolderStyle = useMemo(() => ({
        width: '100%',
        padding: '0 4px',
        color: style?.color || 'gray',
    }), [style])

    const handleSelectItem = (value: string) => () => {
        onChange(value)
        setIsVisible(false)
    }

    const label = useMemo(() => {
        if (value) {
            return items.find((item) => item.value === value)?.label || value
        }
        return placeholder
    }, [items, placeholder, value])

    return (
        <>
            {isVisible && (
                <ModalFrame visible transparent>
                    <Frame onClick={() => setIsVisible(false)}>
                        <Container>
                            <Modal>
                                <ModalItem onClick={handleSelectItem('')}>
                                    <Text colorVariant="darkGray">
                                        {placeholder}
                                    </Text>
                                </ModalItem>
                                {items.map((item) => (
                                    <ModalItem
                                        key={item.value}
                                        onClick={handleSelectItem(item.value)}
                                    >
                                        <Text colorVariant="black">
                                            {item.label}
                                        </Text>
                                    </ModalItem>
                                )) }
                            </Modal>
                        </Container>
                    </Frame>
                </ModalFrame>
            )}
            <Picker style={style as React.CSSProperties} onClick={() => setIsVisible(true)}>
                <Text style={placeHolderStyle}>
                    {label}
                </Text>
                {arrowsEnabled && (
                    <div>
                        <SvgIcon
                            family="AntDesign"
                            name="up"
                            color="#6f737b"
                            size={13}
                        />
                        <SvgIcon
                            family="AntDesign"
                            name="down"
                            color="#6f737b"
                            size={13}
                        />
                    </div>
                )}
            </Picker>
        </>
    )
}

export default ControlPicker
