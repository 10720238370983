import styled, { keyframes } from 'styled-components/macro'

const ghostAnim = keyframes`
    0% { opacity:0; }
    5% { opacity:0.75 }
    10% { opacity:1 }
    82% { opacity:1 }
    83% { opacity:0.25 }
    95% { opacity:0 }
    100% { opacity:0 }
`

export default styled.div`
    & > .toast-item {
        position: absolute;
        z-index: 1000;
        color: #000;
        left: 50%;
        transform: translateX(-50%);
        font-size: 11px;
        padding: 14px 21px;
        background-color: #e1dcefbf;
        border-radius: 20px;
        visibility: hidden;
        animation-delay: 0s;
        animation-timing-function: linear;
        width: max-content;
        max-width: calc(100% - 2rem);
        word-wrap: break-word;

        &.anim {
            visibility: visible;
            animation-name: ${ghostAnim};
        }

        // Duration
        &.long {
            animation-duration: 5s;
        }
        &.short {
            animation-duration: 3.3s;
        }

        // Position
        &.top {
            top: 1rem;
        }
        &.center {
            top: 50%;
            transform: translate(-50%, -50%);
        }
        &.bottom {
            bottom: 1rem;
        }
    }
`
