import dataURItoBlob from './dataURItoBlob'

type Args = {
    path: string
    width: number
    height: number
}

type Response = {
    uri: string;
    path: string
    width: number
    height: number
}

const resizerImageFromUrl = (args: Args) => new Promise<Response>((resolve, reject) => {
    const imgToResize = new Image()
    imgToResize.crossOrigin = 'Anonymous'
    imgToResize.onload = () => {
        const canvas = document.createElement('canvas')
        const context = canvas.getContext('2d')

        if (!context) return reject()
        canvas.width = args.width
        canvas.height = args.height

        context.drawImage(imgToResize, 0, 0, args.width, args.height)
        const imgBase64 = canvas.toDataURL('image/png')
        const blob = dataURItoBlob(imgBase64)
        const uri = URL.createObjectURL(blob)
        return resolve({ ...args, uri })
    }
    imgToResize.onerror = () => reject()
    imgToResize.src = args.path
})

export default resizerImageFromUrl
