import React, { useRef, useEffect } from 'react'
import { AppAtomRenders } from '@apphiveio/controlsmanager/types/RenderComponents'
import placeHolderImageSrc from 'assets/images/videoPlaceholder.jpg'

import { VideoWrapper, Video, PlaceHolderImage } from './style'

const ControlVideo: AppAtomRenders['Video'] = ({
    style,
    videoConfig,
    paused,
    muted,
    play,
    showControls,
    volume,
}) => {
    const videoRef = useRef<HTMLVideoElement>(null)

    useEffect(() => {
        const video = videoRef.current
        if (video) {
            if (volume) {
                video.volume = volume
            }
            if (paused) {
                video.pause()
            } else if (play) {
                video.play()
            }
        }
    }, [paused, play, volume])

    return (
        <VideoWrapper style={style as React.CSSProperties}>
            {videoConfig?.uri ? (
                <Video ref={videoRef} muted={muted} controls={showControls}>
                    <source src={videoConfig.uri} type="video/mp4" />
                </Video>
            ) : (
                <PlaceHolderImage src={placeHolderImageSrc} />
            )}
        </VideoWrapper>
    )
}
export default ControlVideo
