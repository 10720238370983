import { useEffect, useContext } from 'react'
import { AppAtomRenders } from '@apphiveio/controlsmanager/types/RenderComponents'
import ControlMapContext from '../ControlMapView/controlMapContext'
import './styles.css'

const ControlMapMarker: AppAtomRenders['MapMarker'] = ({
    title,
    description,
    coordinate,
    icon,
    width = 30,
    height = 30,
    onPress,
}) => {
    const { googleMap, infoWindow } = useContext(ControlMapContext)

    useEffect(() => {
        const marker = new google.maps.Marker({
            position: { lat: coordinate.latitude, lng: coordinate.longitude },
            icon: icon ? {
                url: icon,
                scaledSize: new google.maps.Size(width, height),
            } : undefined,
        })

        if (googleMap && infoWindow) {
            googleMap.addListener('click', () => {
                infoWindow.close()
            })
            marker.addListener('click', () => {
                onPress?.()
                infoWindow.setContent(`
                    <div><span><b>${title || ''}</b></span></div>
                    <p>${description || ''}</p>
                `)
                infoWindow.open({
                    anchor: marker,
                    map: googleMap,
                    shouldFocus: true,
                })
            })
            marker.setMap(googleMap)
        }

        return () => {
            marker.setMap(null)
        }
    }, [
        coordinate.latitude,
        coordinate.longitude,
        description,
        infoWindow,
        googleMap,
        title,
        icon,
        width,
        height,
        onPress,
    ])
    return null
}

export default ControlMapMarker
