import React, { useState, useMemo } from 'react'
import { AppAtomRenders } from '@apphiveio/controlsmanager/types/RenderComponents'
import getNumberFromUnknown from '@apphiveio/controlsmanager/shared/getNumberFromUnknown'
import { strDateToDate } from '@apphiveio/controlsmanager/shared/formatDate'
import { TouchableOpacity, Text } from 'react-native-web'
import 'react-calendar/dist/Calendar.css'
import Calendar from 'react-calendar'
import TimeKeeper from 'react-timekeeper'
import DialogWrapper from '../DialogWrapper'

const convertDateToTime = (date: Date) => {
    const hours24 = date.getHours()

    const hours = hours24 <= 12 ? hours24 : (hours24 - 12)

    const timeTag = hours24 >= 12 ? 'pm' : 'am'

    const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
    return `${hours}:${minutes} ${timeTag}`
}

const ControlDatePicker: AppAtomRenders['DatePicker'] = ({
    date,
    mode,
    maxDate,
    minDate,
    style,
    customStyles,
    onDateChange,
}) => {
    const fullFormat = useMemo(() => (mode === 'time' ? 'HH:mm:ss a' : 'DD-MM-YYYY HH:mm:ss a'), [mode])
    const dateFromControl = useMemo(() => strDateToDate(date, fullFormat), [date, fullFormat])
    const seconds = useMemo(() => dateFromControl.getSeconds(), [dateFromControl])
    const [dateBeforeOk, setDateBeforeOk] = useState(dateFromControl)
    const [timeBeforeOk, setTimeBeforeOk] = useState<string>(convertDateToTime(dateFromControl))
    const [dateTimeModeSelected, setDateTimeModeSelected] = useState(false)
    const [openDialog, setOpenDialog] = useState(false)

    const onOk = () => {
        const timeTag = timeBeforeOk.slice(timeBeforeOk.length - 2, timeBeforeOk.length)
        const hours12 = getNumberFromUnknown(timeBeforeOk.split(':')[0])
        const hours24 = (hours12 === 12 && timeTag === 'am') || (timeTag === 'pm' && hours12 !== 12)
            ? (hours12 + 12) : hours12
        const minutes = getNumberFromUnknown(timeBeforeOk.split(':')[1].slice(0, 2))
        const newDate = new Date(dateBeforeOk.getTime())
        const day = newDate.getDate()
        const month = newDate.getMonth()
        const year = newDate.getFullYear()
        newDate.setHours(hours24)
        newDate.setMinutes(minutes)
        newDate.setSeconds(seconds)
        newDate.setDate(day)
        newDate.setMonth(month)
        newDate.setFullYear(year)
        onDateChange(newDate)
        setOpenDialog(false)
        setDateTimeModeSelected(false)
    }

    const onCancel = () => {
        setDateBeforeOk(dateFromControl)
        setTimeBeforeOk(convertDateToTime(dateFromControl))
        setOpenDialog(false)
        setDateTimeModeSelected(false)
    }

    return (
        <>
            <TouchableOpacity
                style={{ ...customStyles.dateInput, ...style }}
                onPress={() => setOpenDialog(true)}
            >
                <Text style={customStyles.dateText}>
                    {date}
                </Text>
            </TouchableOpacity>
            {openDialog && (
                <DialogWrapper
                    mode={mode}
                    dateTimeModeSelected={dateTimeModeSelected}
                    onSetDateTimeModeSelected={setDateTimeModeSelected}
                    onOk={onOk}
                    onCancel={onCancel}
                >
                    {(mode === 'date' || (mode === 'datetime' && !dateTimeModeSelected)) && (
                        <Calendar
                            value={dateBeforeOk}
                            minDate={minDate ? new Date(minDate) : undefined}
                            maxDate={maxDate ? new Date(maxDate) : undefined}
                            onChange={setDateBeforeOk}
                        />
                    )}
                    {(mode === 'time' || (mode === 'datetime' && dateTimeModeSelected)) && (
                        <div>
                            <TimeKeeper
                                time={timeBeforeOk}
                                switchToMinuteOnHourSelect
                                onChange={(timeMark) => {
                                    setTimeBeforeOk(timeMark.formatted12)
                                }}
                            />
                        </div>
                    )}
                </DialogWrapper>
            )}
        </>
    )
}
export default ControlDatePicker
