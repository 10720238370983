import styled from 'styled-components/macro'

export const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`

export const StyledCheckbox = styled.div<{
  checkColor: string;
}>`
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: transparent;
    border-radius: 3px;
    transition: all 150ms;
    border: 2px solid ${({ checkColor }) => checkColor};
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    place-content: flex-start;
    box-sizing: border-box;
    && svg {
      width: 100%;
      height: 100%;
      fill: ${({ checkColor }) => checkColor};
    }
`
