import React from 'react'
import {
    Modal,
    View,
    Text,
    TouchableOpacity,
} from 'react-native-web'
import style from './style'

type Props = {
    title?: string;
    description?: string;
    children?: React.ReactNode;
    mode?: string;
    dateTimeModeSelected?: boolean;
    onOk: () => void;
    onCancel?: () => void;
    onSetDateTimeModeSelected?: (value: boolean) => void;
}

const DialogWrapper: React.FC<Props> = ({
    title,
    description,
    children,
    mode,
    dateTimeModeSelected,
    onOk,
    onCancel,
    onSetDateTimeModeSelected,
}) => (
    <Modal visible animationType="slide" transparent>
        <View style={style.frame}>
            <View
                style={
                    [style.container, { backgroundColor: mode !== 'time' && style.container.backgroundColor }]
                }
            >
                {
                    mode === 'datetime' && onSetDateTimeModeSelected ? (
                        <View style={style.dateTimeHeader}>
                            <Text
                                onClick={() => onSetDateTimeModeSelected(false)}
                                style={[style.title, !dateTimeModeSelected && style.modeSelected]}
                            >
                                Date
                            </Text>
                            <Text
                                onClick={() => onSetDateTimeModeSelected(true)}
                                style={[style.title, dateTimeModeSelected && style.modeSelected]}
                            >
                                Time
                            </Text>
                        </View>
                    ) : (
                        <View style={style.header}>
                            {title && (<Text style={style.title}>{title}</Text>)}
                            {description && (<Text style={style.description}>{description}</Text>)}
                        </View>
                    )
                }
                <View
                    style={[
                        style.content,
                        { backgroundColor: mode !== 'time' && style.container.backgroundColor },
                    ]}
                >
                    {children}
                </View>
                <View style={mode === 'time' ? style.timePickerfooter : style.footer}>
                    <TouchableOpacity style={style.button} onPress={onCancel}>
                        <Text style={style.buttonText}>CANCEL</Text>
                    </TouchableOpacity>
                    <TouchableOpacity style={style.button} onPress={onOk}>
                        <Text style={style.buttonText}>OK</Text>
                    </TouchableOpacity>
                </View>
            </View>
        </View>
    </Modal>
)

export default DialogWrapper
